define("discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/latest", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/lib/ajax", "discourse/lib/preload-store", "discourse/models/topic-list", "discourse-common/helpers/i18n", "discourse/plugins/discourse-local-guides-connect/discourse/components/topic-formatter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _service, _ajax, _preloadStore, _topicList, _i18n, _topicFormatter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomepageLatest extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "latestTopics", [_tracking.tracked], function () {
      return [];
    }))();
    #latestTopics = (() => (dt7948.i(this, "latestTopics"), void 0))();
    getLatestTopics() {
      if (_preloadStore.default.get("latest_homepage_topics")) {
        _preloadStore.default.getAndRemove("latest_homepage_topics").then(preloadedTopics => {
          this.latestTopics = _topicList.default.topicsFrom(this.store, preloadedTopics);
        });
      } else {
        (0, _ajax.ajax)("/homepage-latest.json").then(topics => {
          this.latestTopics = _topicList.default.topicsFrom(this.store, topics);
        });
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "getLatestTopics", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="g-latest" {{didInsert this.getLatestTopics}}>
          <h3>
            {{i18n "homepage.latest.title"}}
          </h3>
    
          <div class="g-latest__list">
            {{#each this.latestTopics as |topic|}}
              <TopicFormatter @topic={{topic}} @showThumbnail={{true}} />
            {{/each}}
          </div>
    
          <a href="/latest" role="button" class="g-button--secondary">
            <span>{{i18n "js.read_more"}}</span>
          </a>
        </section>
      
    */
    {
      "id": "4Zm3nied",
      "block": "[[[1,\"\\n    \"],[11,\"section\"],[24,0,\"g-latest\"],[4,[32,0],[[30,0,[\"getLatestTopics\"]]],null],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[\"homepage.latest.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"g-latest__list\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"latestTopics\"]]],null]],null],null,[[[1,\"          \"],[8,[32,2],null,[[\"@topic\",\"@showThumbnail\"],[[30,1],true]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[10,3],[14,6,\"/latest\"],[14,\"role\",\"button\"],[14,0,\"g-button--secondary\"],[12],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,1],[\"js.read_more\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"topic\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/latest.js",
      "scope": () => [_didInsert.default, _i18n.default, _topicFormatter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HomepageLatest;
});