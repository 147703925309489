define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/select-kit-row-override", ["exports", "@ember/object", "discourse/lib/plugin-api"], function (_exports, _object, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function changeSelectKitRow(api) {
    api.modifyClass("component:select-kit/select-kit-row", {
      pluginId: "change-selectkit-row",
      title: (0, _object.computed)("rowTitle", "item.title", "rowName", function () {
        return this.getProperty(this.item, "description") || this.getProperty(this.item, "title") || this.rowTitle || this.rowName;
      })
    });
  }
  var _default = _exports.default = {
    name: "change-selectkit-row",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", changeSelectKitRow);
    }
  };
});