define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/topic-footer-main-buttons-before-create/topic-invite-button", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/modal/create-invite", "discourse/models/composer", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "I18n", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _service, _createInvite, _composer3, _i18n, _getUrl, _I18n, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicInviteButton extends _component.default {
    static shouldRender(outletArgs) {
      const eventDetails = outletArgs.topic?.postStream?.posts[0]?.event;
      const eventDate = eventDetails?.ends_at || eventDetails?.starts_at;
      if (!eventDate) {
        return false;
      }
      return !moment(eventDate).isBefore(moment());
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    openInviteModal(menu) {
      menu.close();
      const topic = this.args.outletArgs?.topic;
      if (topic) {
        this.modal.show(_createInvite.default, {
          model: {
            inviteToTopic: true,
            topics: [topic],
            topicId: topic.id,
            topicTitle: topic.title
          }
        });
      } else {
        this.dialog.alert(_I18n.default.t("generic_error_with_reason", {
          error: `Topic data not found.`
        }));
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "openInviteModal", [_object.action]))();
    startPrivateMessage(menu) {
      menu.close();
      const topic = this.args.outletArgs.topic;
      if (topic) {
        this.composer.open({
          action: _composer3.default.PRIVATE_MESSAGE,
          recipients: "",
          topicTitle: `${_I18n.default.t("js.composer.meetup_invitation.title")} ${topic.title}`,
          topicBody: `${(0, _getUrl.getAbsoluteURL)(topic.url)}/1`,
          archetypeId: "private_message",
          tags: [],
          draftKey: _composer3.default.NEW_PRIVATE_MESSAGE_KEY
        });
      } else {
        this.dialog.alert(_I18n.default.t("generic_error_with_reason", {
          error: `Topic data not found.`
        }));
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "startPrivateMessage", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          @placement="auto"
          class="btn-default topic-footer-button g-invite-button-topic"
        >
          <:trigger>
            <span>{{i18n "js.topic.share.invite_users"}}</span>
          </:trigger>
          <:content as |args|>
            <ul role="menu" class="g-invite-menu">
              <li>
                <button
                  type="button"
                  class="btn-flat g-invite-menu__username"
                  {{on "click" (fn this.startPrivateMessage args)}}
                >
                  {{i18n "js.topic.invite_by_username"}}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn-flat g-invite-menu__email"
                  {{on "click" (fn this.openInviteModal args)}}
                >
                  {{i18n "js.topic.invite_by_email"}}
                </button>
              </li>
            </ul>
    
          </:content>
        </DMenu>
      
    */
    {
      "id": "9SPZoYOK",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-default topic-footer-button g-invite-button-topic\"]],[[\"@placement\"],[\"auto\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n        \"],[10,1],[12],[1,[28,[32,1],[\"js.topic.share.invite_users\"],null]],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,\"ul\"],[14,\"role\",\"menu\"],[14,0,\"g-invite-menu\"],[12],[1,\"\\n          \"],[10,\"li\"],[12],[1,\"\\n            \"],[11,\"button\"],[24,0,\"btn-flat g-invite-menu__username\"],[24,4,\"button\"],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"startPrivateMessage\"]],[30,1]],null]],null],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"js.topic.invite_by_username\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"li\"],[12],[1,\"\\n            \"],[11,\"button\"],[24,0,\"btn-flat g-invite-menu__email\"],[24,4,\"button\"],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"openInviteModal\"]],[30,1]],null]],null],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"js.topic.invite_by_email\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n      \"]],[1]]]]],[1,\"\\n  \"]],[\"args\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/topic-footer-main-buttons-before-create/topic-invite-button.js",
      "scope": () => [_dMenu.default, _i18n.default, _modifier.on, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicInviteButton;
});