define("discourse/plugins/discourse-local-guides-connect/discourse/components/composer/select-tags", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-update", "truth-helpers", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse/plugins/discourse-local-guides-connect/discourse/components/chips", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didUpdate, _truthHelpers, _ajax, _ajaxError, _i18n, _chips, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SelectTags extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "tags", [_tracking.tracked]))();
    #tags = (() => (dt7948.i(this, "tags"), void 0))();
    constructor() {
      super(...arguments);
      this.fetchTags();
    }
    async fetchTags() {
      const data = {
        q: "",
        filterForInput: true,
        categoryId: this.args.categoryId
      };
      if (this.value) {
        data.selected_tags = this.value;
      }
      try {
        this.tags = (await (0, _ajax.ajax)("/tags/filter/search", {
          data
        })).results;
        const tagsToRemove = this.args.value.filter(tag => !this.tags.find(t => t.id === tag));
        tagsToRemove.forEach(this.args.onChange);
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "fetchTags", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="g-composer__multi" {{didUpdate this.fetchTags @categoryId}}>
          {{#if (gt this.tags.length 0)}}
            <div class="g-composer__label">
              <span>
                {{#if @shouldRenderMeetup}}
                  {{i18n "composer.tags.meetup_label"}}
                {{else}}
                  {{i18n "composer.tags.label"}}
                {{/if}}
              </span>
              <span class="g-composer__label-instructions">
                {{i18n "composer.select_many"}}
              </span>
            </div>
    
            <Chips
              class="--tags"
              @isTag="true"
              @items={{this.tags}}
              @selected={{@value}}
              @onSelect={{@onChange}}
              @validation={{@validation}}
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "Ir+ZMiqL",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"g-composer__multi\"],[4,[32,0],[[30,0,[\"fetchTags\"]],[30,1]],null],[12],[1,\"\\n\"],[41,[28,[32,1],[[30,0,[\"tags\",\"length\"]],0],null],[[[1,\"        \"],[10,0],[14,0,\"g-composer__label\"],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"              \"],[1,[28,[32,2],[\"composer.tags.meetup_label\"],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[32,2],[\"composer.tags.label\"],null]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"g-composer__label-instructions\"],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"composer.select_many\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,3],[[24,0,\"--tags\"]],[[\"@isTag\",\"@items\",\"@selected\",\"@onSelect\",\"@validation\"],[\"true\",[30,0,[\"tags\"]],[30,3],[30,4],[30,5]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@categoryId\",\"@shouldRenderMeetup\",\"@value\",\"@onChange\",\"@validation\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/composer/select-tags.js",
      "scope": () => [_didUpdate.default, _truthHelpers.gt, _i18n.default, _chips.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SelectTags;
});