define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/bookmark-list-before-link/bookmark", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse/plugins/discourse-local-guides-connect/discourse/components/topic-formatter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _topicFormatter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BookmarkItem extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topic", [_tracking.tracked]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    constructor() {
      super(...arguments);
      const topic = {
        ...this.args.outletArgs.bookmark
      };
      delete topic.deleted;
      this.topic = this.store.createRecord("topic", {
        ...topic,
        id: topic.topic_id,
        posters: [{
          user: topic.user
        }]
      });
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <TopicFormatter @topic={{this.topic}} />
      
    */
    {
      "id": "lmnwA0BJ",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,0,[\"topic\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/bookmark-list-before-link/bookmark.js",
      "scope": () => [_topicFormatter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BookmarkItem;
});