define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/format-meetup-time", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatMeetupTime;
  function formatMeetupTime(a, b) {
    const start = moment.utc(a).tz(moment.tz.guess());
    const startDate = start.format(_discourseI18n.default.t("dates.long_with_year_no_time"));
    if (!b) {
      const startTime = start.format(_discourseI18n.default.t("dates.time_with_zone"));
      return `<span>${startDate} ${startTime}</span>`;
    }
    const startTime = start.format(_discourseI18n.default.t("dates.time"));
    const end = moment.utc(b).tz(moment.tz.guess());
    const endDate = end.format(_discourseI18n.default.t("dates.long_with_year_no_time"));
    const endTime = end.format(_discourseI18n.default.t("dates.time_with_zone"));
    if (startDate === endDate) {
      return `<span>${startDate} ${startTime}</span><span>${endTime}</span>`;
    }
    return `<span>${startDate} ${startTime}</span><span>${endDate} ${endTime}</span>`;
  }
});