define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/lgc-custom-translations", ["exports", "discourse/plugins/discourse-local-guides-connect/discourse/lib/lgc-custom-translations"], function (_exports, _lgcCustomTranslations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "lgc-custom-translations",
    initialize(container) {
      _lgcCustomTranslations.default.append(container.lookup("service:site-settings"));
    }
  };
});