define("discourse/plugins/discourse-local-guides-connect/discourse/components/invite-post-menu", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse/components/modal/create-invite", "discourse/models/composer", "discourse-common/lib/get-url", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dropdownMenu, _createInvite, _composer3, _getUrl, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InvitePostMenu extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    openInviteModal() {
      const topic = this.args.data?.post?.topic;
      if (!topic) {
        this.dialog.alert(_I18n.default.t("generic_error_with_reason", {
          error: "Topic data not found."
        }));
        return;
      }
      this.modal.show(_createInvite.default, {
        model: {
          inviteToTopic: true,
          topics: [topic],
          topicId: topic.id,
          topicTitle: topic.title
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "openInviteModal", [_object.action]))();
    startPrivateMessage() {
      const topic = this.args.data?.post?.topic;
      if (!topic) {
        this.dialog.alert(_I18n.default.t("generic_error_with_reason", {
          error: "Topic data not found."
        }));
        return;
      }
      this.composer.open({
        action: _composer3.default.PRIVATE_MESSAGE,
        recipients: "",
        topicTitle: `${_I18n.default.t("js.composer.meetup_invitation.title")} ${topic.title}`,
        topicBody: `${(0, _getUrl.getAbsoluteURL)(topic.url)}/1`,
        archetypeId: "private_message",
        tags: [],
        draftKey: _composer3.default.NEW_PRIVATE_MESSAGE_KEY
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "startPrivateMessage", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DropdownMenu as |dropdown|>
          <dropdown.item>
            <DButton
              @label="js.topic.invite_by_username"
              @icon="plus-user"
              class="btn-flat g-invite-menu__username"
              @action={{this.startPrivateMessage}}
            />
          </dropdown.item>
    
          <dropdown.item>
            <DButton
              @label="js.topic.invite_by_email"
              @icon="plus-user"
              class="btn-flat g-invite-menu__email"
              @action={{this.openInviteModal}}
            />
          </dropdown.item>
        </DropdownMenu>
      
    */
    {
      "id": "vMOESHTW",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[32,1],[[24,0,\"btn-flat g-invite-menu__username\"]],[[\"@label\",\"@icon\",\"@action\"],[\"js.topic.invite_by_username\",\"plus-user\",[30,0,[\"startPrivateMessage\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[32,1],[[24,0,\"btn-flat g-invite-menu__email\"]],[[\"@label\",\"@icon\",\"@action\"],[\"js.topic.invite_by_email\",\"plus-user\",[30,0,[\"openInviteModal\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"dropdown\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/invite-post-menu.js",
      "scope": () => [_dropdownMenu.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = InvitePostMenu;
});