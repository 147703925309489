define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/header-search-on-mobile", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.modifyClass("component:header-search", {
      pluginId: "header-search-on-mobile",
      get shouldDisplay() {
        return this.displayForUser;
      }
    });
  });
});