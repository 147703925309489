define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/discovery-above/custom-categories", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/categories", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _categories, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoveryAboveCustomCategories extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (eq this.router.currentRouteName "discovery.categories")}}
          <HomepageCategories />
        {{/if}}
      
    */
    {
      "id": "frBpyuWB",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"router\",\"currentRouteName\"]],\"discovery.categories\"],null],[[[1,\"      \"],[8,[32,1],null,null,null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/discovery-above/custom-categories.js",
      "scope": () => [_truthHelpers.eq, _categories.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscoveryAboveCustomCategories;
});