define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/format-month-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatMonthDay;
  function formatMonthDay(dt) {
    const month = moment(dt).format("MMM");
    const day = moment(dt).format("D");
    return `<span class='month'>${month}</span><span class='day'>${day}</span>`;
  }
});