define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/below-site-header/google-translate-placeholder", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GoogleTranslatePlaceholder = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div id="g-translate-widget"></div>
  
  */
  {
    "id": "d4fg/hc9",
    "block": "[[[1,\"\\n  \"],[10,0],[14,1,\"g-translate-widget\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/below-site-header/google-translate-placeholder.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "google-translate-placeholder:GoogleTranslatePlaceholder"));
  var _default = _exports.default = GoogleTranslatePlaceholder;
});