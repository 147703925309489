define("discourse/plugins/discourse-local-guides-connect/discourse/components/location-field", ["exports", "@glimmer/component", "@ember/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _didInsert, _willDestroy, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocationField extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "locationAutocomplete", [_service.service]))();
    #locationAutocomplete = (() => (dt7948.i(this, "locationAutocomplete"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Input
          {{didInsert this.locationAutocomplete.setup @onChange @citiesOnly}}
          {{willDestroy this.locationAutocomplete.teardown}}
          @type="search"
          id="g-location"
          name="g-location"
          @value={{@value}}
          ...attributes
        />
      
    */
    {
      "id": "wkm+VVIP",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,1,\"g-location\"],[24,3,\"g-location\"],[17,1],[4,[32,1],[[30,0,[\"locationAutocomplete\",\"setup\"]],[30,3],[30,4]],null],[4,[32,2],[[30,0,[\"locationAutocomplete\",\"teardown\"]]],null]],[[\"@type\",\"@value\"],[\"search\",[30,2]]],null],[1,\"\\n  \"]],[\"&attrs\",\"@value\",\"@onChange\",\"@citiesOnly\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/location-field.js",
      "scope": () => [_component2.Input, _didInsert.default, _willDestroy.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LocationField;
});