define("discourse/plugins/discourse-local-guides-connect/discourse/components/tags-intersection-chooser", ["exports", "discourse-common/lib/helpers", "I18n", "select-kit/components/mini-tag-chooser"], function (_exports, _helpers, _I18n, _miniTagChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _miniTagChooser.default.extend({
    pluginApiIdentifiers: ["tags-intersection-chooser"],
    attributeBindings: ["selectKit.options.categoryId:category-id"],
    classNames: ["tags-intersection-chooser"],
    mainTag: null,
    additionalTags: null,
    selectKitOptions: {
      closeOnChange: true
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.set("value", (0, _helpers.makeArray)(this.mainTag).concat((0, _helpers.makeArray)(this.additionalTags)));
    },
    modifyNoSelection() {
      return this.defaultItem(null, _I18n.default.t(this.selectKit.options.none ?? "tagging.choose_for_topic"));
    }
  });
});