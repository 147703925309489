define("discourse/plugins/discourse-local-guides-connect/discourse/services/location-autocomplete", ["exports", "@ember/object", "@ember/runloop", "@ember/service"], function (_exports, _object, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocationAutocompleteService extends _service.default {
    autocompleteListener = null;
    locationAutocomplete = null;
    setup(locationInput, _ref) {
      let [onChange, citiesOnly = false] = _ref;
      (0, _runloop.next)(() => {
        if (typeof window.google === "undefined") {
          return;
        }
        if (locationInput) {
          const options = {
            types: citiesOnly ? ["(cities)"] : ["address"]
          };
          this.locationAutocomplete = new window.google.maps.places.Autocomplete(locationInput, options);
          this.locationAutocomplete.setFields(["formatted_address", "geometry", "address_components"]);
          this.autocompleteListener = this.locationAutocomplete.addListener("place_changed", () => {
            const place = this.locationAutocomplete.getPlace();
            const country = place.address_components.find(c => c.types.includes("country"));
            const locality = place.address_components.find(c => c.types.includes("locality"));
            const adminAreaLevel2 = place.address_components.find(c => c.types.includes("administrative_area_level_2"));
            const updatedLocation = {
              address: place.formatted_address,
              city: locality?.long_name ?? adminAreaLevel2?.long_name,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              country: country?.short_name
            };
            onChange?.(updatedLocation);
          });
        }
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "setup", [_object.action]))();
    teardown() {
      if (typeof window.google === "undefined") {
        return;
      }
      if (this.autocompleteListener) {
        window.google.maps.event.removeListener(this.autocompleteListener);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "teardown", [_object.action]))();
  }
  _exports.default = LocationAutocompleteService;
});