define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-and-creators/type", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Type = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <@outletArgs.Status />
  
    <@outletArgs.Separator />
  
    {{#if @outletArgs.event.customFields.type}}
      <span class="event-type">{{@outletArgs.event.customFields.type}}</span>
      <@outletArgs.Separator />
    {{/if}}
  
    <@outletArgs.Creator />
  
  */
  {
    "id": "Xzul+MFk",
    "block": "[[[1,\"\\n  \"],[8,[30,1,[\"Status\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"Separator\"]],null,null,null],[1,\"\\n\\n\"],[41,[30,1,[\"event\",\"customFields\",\"type\"]],[[[1,\"    \"],[10,1],[14,0,\"event-type\"],[12],[1,[30,1,[\"event\",\"customFields\",\"type\"]]],[13],[1,\"\\n    \"],[8,[30,1,[\"Separator\"]],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[30,1,[\"Creator\"]],null,null,null],[1,\"\\n\"]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-and-creators/type.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "type:Type"));
  var _default = _exports.default = Type;
});