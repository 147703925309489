define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/panel-body-bottom/view-all-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PanelBodyBottomViewAllButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    transitionToNotifications() {
      this.router.transitionTo("userNotifications", this.currentUser.username);
    }
    static #_3 = (() => dt7948.n(this.prototype, "transitionToNotifications", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @label="review.view_all"
          @action={{this.transitionToNotifications}}
          class="notifications__more"
        />
      
    */
    {
      "id": "yD4nQbtt",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"notifications__more\"]],[[\"@label\",\"@action\"],[\"review.view_all\",[30,0,[\"transitionToNotifications\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/panel-body-bottom/view-all-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PanelBodyBottomViewAllButton;
});