define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/tags-to-country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tagsToCountry;
  function tagsToCountry(tags) {
    if (!tags) {
      return;
    }
    const countryCode = tags.find(tag => tag.length === 2 && /^[A-Z]{2}$/.test(tag));
    return countryCode ? countryCode : null;
  }
});