define("discourse/plugins/discourse-local-guides-connect/discourse/components/composer/select-category", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/template", "discourse/models/permission-type", "discourse/plugins/discourse-local-guides-connect/discourse/components/chips", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _template, _permissionType, _chips, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SelectCategory extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "categories", [_tracking.tracked]))();
    #categories = (() => (dt7948.i(this, "categories"), void 0))();
    constructor() {
      super(...arguments);
      this.fetchCategories();
    }
    get selectedCategory() {
      // TODO use lazily loaded categories
      return this.site.categoriesList.find(category => category.id === this.args.value);
    }
    fetchCategories() {
      const selected = this.selectedCategory;
      if (selected?.read_restricted && !selected?.has_children) {
        this.categories = [selected];
        return;
      }
      if (selected?.read_restricted && selected?.has_children) {
        this.categories = this.site.categoriesList.filter(category => (category.id === selected.id || category.parentCategory?.id === selected.id) && _permissionType.default.FULL === category.permission);
        return;
      }
      // TODO use lazily loaded categories
      this.categories = this.site.categoriesList.filter(category => _permissionType.default.FULL === category.permission && !category.read_restricted && category?.id !== parseInt(this.siteSettings.meetup_category, 10) && !category.isUncategorizedCategory && category.name !== "Community guidelines" // TODO hide no-create categories
      );
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Chips
          @items={{this.categories}}
          @selected={{@value}}
          @onSelect={{@onChange}}
          @max={{1}}
          @validation={{@validation}}
        />
        {{#if this.selectedCategory.description}}
          <span class="g-chips__description">
            {{htmlSafe this.selectedCategory.description}}
          </span>
        {{/if}}
      
    */
    {
      "id": "3fIOtaHz",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@items\",\"@selected\",\"@onSelect\",\"@max\",\"@validation\"],[[30,0,[\"categories\"]],[30,1],[30,2],1,[30,3]]],null],[1,\"\\n\"],[41,[30,0,[\"selectedCategory\",\"description\"]],[[[1,\"      \"],[10,1],[14,0,\"g-chips__description\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[[30,0,[\"selectedCategory\",\"description\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@value\",\"@onChange\",\"@validation\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/composer/select-category.js",
      "scope": () => [_chips.default, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SelectCategory;
});