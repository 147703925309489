define("discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/carousel", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse/lib/load-script", "discourse-common/helpers/i18n", "discourse/plugins/discourse-local-guides-connect/discourse/components/topic-formatter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _didInsert, _loadScript, _i18n, _topicFormatter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomepageCarousel extends _component.default {
    willDestroy() {
      super.willDestroy();
      // clean up any set event listeners on component destruction
      if (typeof this.cleanupActions === "function") {
        this.cleanupActions();
      }
      this.emblaApi?.destroy();
    }
    get placeholderBanner() {
      return "/plugins/discourse-local-guides-connect/images/placeholder.png";
    }
    async setupCarousel(element) {
      await (0, _loadScript.default)("/plugins/discourse-local-guides-connect/javascripts/embla-carousel.umd.js");
      await (0, _loadScript.default)("/plugins/discourse-local-guides-connect/javascripts/embla-carousel-wheel-gestures.umd.js");
      const OPTIONS = {
        loop: false
      };
      const viewportNode = element.querySelector(".g-carousel__viewport");
      const prevBtnNode = element.querySelector(".g-carousel__button--prev");
      const nextBtnNode = element.querySelector(".g-carousel__button--next");
      const WheelGesturesPlugin = window.EmblaCarouselWheelGestures;
      // Access EmblaCarousel as a global variable
      this.emblaApi = window.EmblaCarousel(viewportNode, OPTIONS, [WheelGesturesPlugin()]);
      // Setup button event listeners and state toggling
      this.setupButtonHandlers(prevBtnNode, nextBtnNode);
      this.toggleButtonStates(prevBtnNode, nextBtnNode);
      // Register carousel event listeners to toggle button states
      this.emblaApi.on("select", () => this.toggleButtonStates(prevBtnNode, nextBtnNode));
      this.emblaApi.on("init", () => this.toggleButtonStates(prevBtnNode, nextBtnNode));
      this.emblaApi.on("reInit", () => this.toggleButtonStates(prevBtnNode, nextBtnNode));
    }
    static #_ = (() => dt7948.n(this.prototype, "setupCarousel", [_object.action]))();
    setupButtonHandlers(prevBtn, nextBtn) {
      const scrollPrev = () => this.emblaApi.scrollPrev();
      const scrollNext = () => this.emblaApi.scrollNext();
      prevBtn.addEventListener("click", scrollPrev, false);
      nextBtn.addEventListener("click", scrollNext, false);
      // Cleanup logic to remove event listeners
      this.cleanupActions = () => {
        prevBtn.removeEventListener("click", scrollPrev, false);
        nextBtn.removeEventListener("click", scrollNext, false);
      };
    }
    static #_2 = (() => dt7948.n(this.prototype, "setupButtonHandlers", [_object.action]))();
    toggleButtonStates(prevBtn, nextBtn) {
      if (this.emblaApi.canScrollPrev()) {
        prevBtn.removeAttribute("disabled");
      } else {
        prevBtn.setAttribute("disabled", "disabled");
      }
      if (this.emblaApi.canScrollNext()) {
        nextBtn.removeAttribute("disabled");
      } else {
        nextBtn.setAttribute("disabled", "disabled");
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleButtonStates", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="g-carousel">
          <h3>
            {{i18n "homepage.carousel.title"}}
          </h3>
    
          <div class="g-carousel__wrapper" {{didInsert this.setupCarousel}}>
            <div class="g-carousel__viewport">
              <div class="g-carousel__container">
                {{#each @topics as |topic|}}
                  <div class="g-carousel__slide-wrapper">
                    <div class="g-carousel__slide">
                      <a
                        href={{concat "/t/" topic.slug "/" topic.id}}
                        class="g-carousel__img"
                      >
                        <img
                          src={{if
                            topic.image_url
                            topic.image_url
                            this.placeholderBanner
                          }}
                          alt={{topic.title}}
                        />
                      </a>
                      <TopicFormatter @topic={{topic}} @showThumbnail={{false}} />
                    </div>
                  </div>
                {{/each}}
              </div>
            </div>
    
            <div class="g-carousel__controls">
              <div class="g-carousel__buttons">
                <button
                  class="g-carousel__button g-carousel__button--prev"
                  aria-label="Previous"
                  type="button"
                >
                </button>
                <button
                  class="g-carousel__button g-carousel__button--next"
                  aria-label="Next"
                  type="button"
                >
                </button>
              </div>
            </div>
          </div>
        </section>
      
    */
    {
      "id": "tadRTsym",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"g-carousel\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"homepage.carousel.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[11,0],[24,0,\"g-carousel__wrapper\"],[4,[32,1],[[30,0,[\"setupCarousel\"]]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"g-carousel__viewport\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"g-carousel__container\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"              \"],[10,0],[14,0,\"g-carousel__slide-wrapper\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"g-carousel__slide\"],[12],[1,\"\\n                  \"],[10,3],[15,6,[28,[32,2],[\"/t/\",[30,2,[\"slug\"]],\"/\",[30,2,[\"id\"]]],null]],[14,0,\"g-carousel__img\"],[12],[1,\"\\n                    \"],[10,\"img\"],[15,\"src\",[52,[30,2,[\"image_url\"]],[30,2,[\"image_url\"]],[30,0,[\"placeholderBanner\"]]]],[15,\"alt\",[30,2,[\"title\"]]],[12],[13],[1,\"\\n                  \"],[13],[1,\"\\n                  \"],[8,[32,3],null,[[\"@topic\",\"@showThumbnail\"],[[30,2],false]],null],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[2]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"g-carousel__controls\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"g-carousel__buttons\"],[12],[1,\"\\n            \"],[10,\"button\"],[14,0,\"g-carousel__button g-carousel__button--prev\"],[14,\"aria-label\",\"Previous\"],[14,4,\"button\"],[12],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,\"button\"],[14,0,\"g-carousel__button g-carousel__button--next\"],[14,\"aria-label\",\"Next\"],[14,4,\"button\"],[12],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@topics\",\"topic\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/homepage/carousel.js",
      "scope": () => [_i18n.default, _didInsert.default, _helper.concat, _topicFormatter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HomepageCarousel;
});