define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/user-main-nav/profile-sections", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _i18n, _getUrl, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProfileSections extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get username() {
      return this.args.outletArgs.model.username;
    }
    get isSummary() {
      return this.router.currentRouteName === "user.summary";
    }
    get isActivity() {
      return this.router.currentRouteName.startsWith("userActivity");
    }
    get isBadges() {
      return this.router.currentRouteName === "user.badges";
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span id="profile-tabs"></span>
        <ul class="g-profile__tabs">
          <li>
            <a
              href={{getUrl (concat "/u/" this.username "/summary#profile-tabs")}}
              class={{if this.isSummary "active" ""}}
            >
              <span>{{i18n "js.user.summary.title"}}</span>
            </a>
          </li>
          <li>
            <a
              href={{getUrl (concat "/u/" this.username "/activity#profile-tabs")}}
              class={{if this.isActivity "active" ""}}
            >
              <span> {{i18n "js.user.activity_stream"}}</span>
            </a>
          </li>
          <li>
            <a
              href={{getUrl (concat "/u/" this.username "/badges#profile-tabs")}}
              class={{if this.isBadges "active" ""}}
            >
              <span> {{i18n "js.badges.title"}}</span>
            </a>
          </li>
        </ul>
      
    */
    {
      "id": "6tC5dc5C",
      "block": "[[[1,\"\\n    \"],[10,1],[14,1,\"profile-tabs\"],[12],[13],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"g-profile__tabs\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[15,6,[28,[32,0],[[28,[32,1],[\"/u/\",[30,0,[\"username\"]],\"/summary#profile-tabs\"],null]],null]],[15,0,[52,[30,0,[\"isSummary\"]],\"active\",\"\"]],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[32,2],[\"js.user.summary.title\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[15,6,[28,[32,0],[[28,[32,1],[\"/u/\",[30,0,[\"username\"]],\"/activity#profile-tabs\"],null]],null]],[15,0,[52,[30,0,[\"isActivity\"]],\"active\",\"\"]],[12],[1,\"\\n          \"],[10,1],[12],[1,\" \"],[1,[28,[32,2],[\"js.user.activity_stream\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[15,6,[28,[32,0],[[28,[32,1],[\"/u/\",[30,0,[\"username\"]],\"/badges#profile-tabs\"],null]],null]],[15,0,[52,[30,0,[\"isBadges\"]],\"active\",\"\"]],[12],[1,\"\\n          \"],[10,1],[12],[1,\" \"],[1,[28,[32,2],[\"js.badges.title\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/user-main-nav/profile-sections.js",
      "scope": () => [_getUrl.default, _helper.concat, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ProfileSections;
});