define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/meetup-form-save", ["exports", "@ember/string", "rsvp", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/plugins/discourse-local-guides-connect/discourse/helpers/category-country", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants", "discourse/plugins/discourse-local-guides-connect/discourse/lib/is-meetup-submission", "discourse/plugins/discourse-local-guides-connect/discourse/lib/meetup-helper", "I18n"], function (_exports, _string, _rsvp, _pluginApi, _composer, _categoryCountry, _constants, _isMeetupSubmission, _meetupHelper, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_TIME_FORMAT = "YYYY-MM-DD HH:mm";
  const BRACKETS_REGEX = /[[\]]/g;
  const VIRTUAL_TYPES = ["virtual", "hybrid"];
  function eventRawContent(_ref) {
    let {
      name,
      startsAt,
      endsAt,
      location,
      type,
      timezone,
      url,
      siteSettings
    } = _ref;
    const reminders = siteSettings.lgc_meetup_notification_definition;
    const startsAtStr = moment(startsAt).tz(timezone).format(EVENT_TIME_FORMAT);
    const endsAtStr = endsAt ? ` end="${moment(endsAt).tz(timezone).format(EVENT_TIME_FORMAT)}"` : "";
    const urlStr = url ? ` url="${url}"` : "";
    const locationString = location ? `location="${location}"` : "";
    return `[event name="${name}" start="${startsAtStr}"${endsAtStr} status="public" timezone="${timezone}" allowedGroups="trust_level_0" ${locationString} type="${type}" reminders="${reminders}"${urlStr}]\n[/event]`;
  }
  var _default = _exports.default = {
    name: "meetup-form-save",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.serializeOnCreate(_constants.LOCATION_FIELD);
        api.serializeToDraft(_constants.LOCATION_FIELD);
        api.serializeToTopic(_constants.LOCATION_FIELD, `topic.${_constants.LOCATION_FIELD}`);
        api.serializeOnCreate(_constants.EVENT_TYPE_FIELD);
        api.serializeToDraft(_constants.EVENT_TYPE_FIELD);
        api.serializeToTopic(_constants.EVENT_TYPE_FIELD, `topic.${_constants.EVENT_TYPE_FIELD}`);
        api.serializeOnCreate(_constants.EVENT_TOPIC_ID_FIELD);
        api.serializeToDraft(_constants.EVENT_TOPIC_ID_FIELD);
        api.serializeToTopic(_constants.EVENT_TOPIC_ID_FIELD, `topic.${_constants.EVENT_TOPIC_ID_FIELD}`);
        api.composerBeforeSave(() => {
          return new _rsvp.Promise((resolve, reject) => {
            const siteSettings = api.container.lookup("service:site-settings");
            const composer = api.container.lookup("service:composer");
            const model = composer.model;

            // skip if it's a reply or an edit to a reply, no tags/location required in this case
            if (model.get("action") === _composer.default.REPLY || model.get("action") === _composer.default.EDIT && !model.get("post.firstPost")) {
              return resolve();
            }
            const isTagValid = model.get("tags").length >= (model.get("category")?.minimum_required_tags ?? 0);
            let isLocationValid = true;
            const categoryId = (0, _isMeetupSubmission.default)(model) ? parseInt(siteSettings.meetup_category, 10) : model.get("categoryId");
            if (
            // ignore location validation for virtual events
            model.get(_constants.EVENT_TYPE_FIELD) !== "virtual" &&
            // ignore location validation for recaps
            !model.get("tags").includes(siteSettings.meetup_recap_tag) && (0, _categoryCountry.isLocationMandatory)(categoryId, siteSettings)) {
              isLocationValid = Boolean(model.get(_constants.LOCATION_FIELD));
            }
            if (!isTagValid || !isLocationValid) {
              composer.set("lastValidatedAt", Date.now());
              return reject();
            }
            if (!(0, _isMeetupSubmission.default)(model)) {
              return resolve();
            }
            const minDate = (0, _meetupHelper.getMeetupMinDate)(model, siteSettings);
            if (!model.get("metaData.event.startsAt") || minDate && model.get("metaData.event.startsAt").isBefore(minDate) || !model.get("metaData.event.url") && VIRTUAL_TYPES.includes(model.get(_constants.EVENT_TYPE_FIELD)) || !model.get("metaData.event.imageUrl")) {
              composer.set("lastValidatedAt", Date.now());
              return reject();
            }
            const name = model.get("title").replace(BRACKETS_REGEX, "");
            const countryData = model.get("local_guides_location.country");
            const address = model.get("local_guides_location.address");
            const country = countryData ? _I18n.default.t(`local_guides_connect.countries.${countryData}`) : "";
            const type = (0, _string.capitalize)(model.get("local_guides_event_type"));
            const eventRaw = eventRawContent({
              ...model.get("metaData.event"),
              name,
              siteSettings,
              location: address || country,
              type
            });
            const imageUrl = model.get("metaData.event.imageShortUrl");
            const imageRaw = imageUrl ? `![${name}](${imageUrl})\n\n` : "";
            const reply = `${imageRaw}${eventRaw}\n\n${model.reply}`;
            if (model.get("action") === "createTopic") {
              const translatedEventType = _I18n.default.t(`meetup_form.type_field.${model.get(_constants.EVENT_TYPE_FIELD)}`);
              const countryName = model.get("local_guides_location.country") ? ` ${_I18n.default.t(`local_guides_connect.countries.${model.get("local_guides_location.country")}`)},` : "";
              const date = model.get("metaData.event.startsAt").format("MMMM DD");
              const title = `[${translatedEventType}]${countryName} ${date}, ${model.get("title")}`;
              model.set("title", title);
            }
            model.setProperties({
              reply,
              metaData: {
                ...model.get("metaData"),
                event: undefined,
                isMeetup: undefined
              }
            });
            return resolve();
          });
        });
        const appEvents = api.container.lookup("service:app-events");
        appEvents.on("topic:created", post => {
          const siteSettings = api.container.lookup("service:site-settings");
          if (post.get("archetype") === "private_message" && post.get("target_recipients") === siteSettings.meetup_submission_group) {
            const dialog = api.container.lookup("service:dialog");
            dialog.dialog({
              message: _I18n.default.t("meetup_form.confirmation_notice"),
              type: "alert",
              confirmButtonLabel: "ok_value"
            });
          }
        });
      });
    }
  };
});