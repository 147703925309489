define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/hide-country-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hideCountryTag;
  function hideCountryTag(value) {
    return value.length === 2;
  }
});