define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/reveal-meetup-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = revealMeetupUrl;
  function revealMeetupUrl(b) {
    const start = moment.utc(b);
    const now = moment();
    const twelveHoursFromNow = moment(now).add(12, "hours");
    if (start.isBefore(now) || start.isBefore(twelveHoursFromNow)) {
      return true;
    } else {
      return false;
    }
  }
});