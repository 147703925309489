define("discourse/plugins/discourse-local-guides-connect/discourse/lib/meetup-helper", ["exports", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMeetupMinDate = getMeetupMinDate;
  _exports.isRecapTopic = isRecapTopic;
  function isRecapTopic(model) {
    return model.get("archetypeId") === "regular" && (model.get(`topic.${_constants.EVENT_TOPIC_ID_FIELD}`) || model.get(_constants.EVENT_TOPIC_ID_FIELD));
  }
  function getMeetupMinDate(model, siteSettings) {
    if (model.get(_constants.EVENT_TOPIC_ID_FIELD) || model.get("categoryId")) {
      return;
    }
    return moment().add(siteSettings.lgc_meetup_form_min_future_days, "days").startOf("hour");
  }
});