define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/category-name", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = categoryName;
  function categoryName(category) {
    return _discourseI18n.default.t(`categories.${category?.slug?.replace(/-/g, "_")}.title`, {
      defaultValue: category?.name
    });
  }
});