define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/meetup-edit-post-menu", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/api", "discourse/models/composer", "I18n", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants"], function (_exports, _ajax, _ajaxError, _api, _composer, _I18n, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    api.addPostMenuButton("editMeetup", attrs => {
      if (attrs.canEdit || attrs.topic.category_id !== parseInt(siteSettings.meetup_category, 10) || attrs.user_id !== api.getCurrentUser()?.id || attrs.topic.tags.includes(siteSettings.meetup_recap_tag)) {
        return;
      }
      return {
        async action(_ref) {
          let {
            post
          } = _ref;
          const dialog = api.container.lookup("service:dialog");
          dialog.confirm({
            title: _I18n.default.t("meetup_form.edit_submission_note.title"),
            message: _I18n.default.t("meetup_form.edit_submission_note.message"),
            confirmButtonLabel: "meetup_form.edit_submission_note.confirm",
            didConfirm: async () => {
              try {
                const response = await (0, _ajax.ajax)(`/posts/${post.id}/raw`, {
                  dataType: "text"
                });
                const composer = api.container.lookup("service:composer");
                composer.open({
                  action: _composer.default.PRIVATE_MESSAGE,
                  recipients: siteSettings.meetup_submission_group,
                  topicTitle: post.event.name,
                  topicBody: response,
                  archetypeId: "private_message",
                  tags: post.topic.tags.filter(tag => tag.length !== 2),
                  draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
                  topic: {
                    [_constants.EVENT_TOPIC_ID_FIELD]: post.topic.id,
                    [_constants.EVENT_TYPE_FIELD]: post.topic[_constants.EVENT_TYPE_FIELD],
                    [_constants.LOCATION_FIELD]: post.topic[_constants.LOCATION_FIELD]
                  }
                });
              } catch (e) {
                (0, _ajaxError.popupAjaxError)(e);
              }
            }
          });
        },
        icon: "pencil-alt",
        className: "edit-meetup",
        title: "meetup_form.edit_meetup",
        label: "meetup_form.edit_meetup",
        position: "first"
      };
    });
  });
});