define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/above-user-summary-stats/custom-user-summary", ["exports", "truth-helpers", "discourse/components/user-summary-category-search", "discourse/helpers/category-link", "discourse-common/helpers/i18n", "discourse/plugins/discourse-local-guides-connect/discourse/components/topic-formatter", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _truthHelpers, _userSummaryCategorySearch, _categoryLink, _i18n, _topicFormatter, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function injectUser(topic, user) {
    topic.set("posters", [{
      user
    }]);
    return topic;
  }
  const CustomUserSummary = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="top-section g-profile__top-topics">
      <h3 class="stats-title">{{i18n "js.user.summary.top_topics"}}</h3>
      {{#each @outletArgs.model.topics as |topic|}}
        <TopicFormatter @topic={{injectUser topic @outletArgs.user}} />
      {{/each}}
    </div>
  
    <div class="top-section g-profile__top-categories">
      <h3 class="stats-title">{{i18n "js.user.summary.top_categories"}}</h3>
      <ul>
        {{#each @outletArgs.model.top_categories as |category|}}
          <li>
            {{categoryLink category}}
            <div class="g-profile__stats">
              {{#unless (eq category.topic_count 0)}}
                <span class="g-profile__stats-topics">
                  <UserSummaryCategorySearch
                    @user={{@outletArgs.user}}
                    @category={{category}}
                    @count={{category.topic_count}}
                  />
                </span>
              {{/unless}}
              {{#if (eq category.post_count 0)}}
                <span class="g-profile__stats-posts">
                  <a>0</a>
                </span>
              {{else}}
                <span class="g-profile__stats-posts">
                  <UserSummaryCategorySearch
                    @user={{@outletArgs.user}}
                    @category={{category}}
                    @count={{category.post_count}}
                  />
                </span>
              {{/if}}
            </div>
          </li>
        {{/each}}
      </ul>
    </div>
  
  */
  {
    "id": "njM5tD2F",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"top-section g-profile__top-topics\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"stats-title\"],[12],[1,[28,[32,0],[\"js.user.summary.top_topics\"],null]],[13],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"model\",\"topics\"]]],null]],null],null,[[[1,\"      \"],[8,[32,1],null,[[\"@topic\"],[[28,[32,2],[[30,2],[30,1,[\"user\"]]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"top-section g-profile__top-categories\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"stats-title\"],[12],[1,[28,[32,0],[\"js.user.summary.top_categories\"],null]],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"model\",\"top_categories\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[1,[28,[32,3],[[30,3]],null]],[1,\"\\n          \"],[10,0],[14,0,\"g-profile__stats\"],[12],[1,\"\\n\"],[41,[51,[28,[32,4],[[30,3,[\"topic_count\"]],0],null]],[[[1,\"              \"],[10,1],[14,0,\"g-profile__stats-topics\"],[12],[1,\"\\n                \"],[8,[32,5],null,[[\"@user\",\"@category\",\"@count\"],[[30,1,[\"user\"]],[30,3],[30,3,[\"topic_count\"]]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[32,4],[[30,3,[\"post_count\"]],0],null],[[[1,\"              \"],[10,1],[14,0,\"g-profile__stats-posts\"],[12],[1,\"\\n                \"],[10,3],[12],[1,\"0\"],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[10,1],[14,0,\"g-profile__stats-posts\"],[12],[1,\"\\n                \"],[8,[32,5],null,[[\"@user\",\"@category\",\"@count\"],[[30,1,[\"user\"]],[30,3],[30,3,[\"post_count\"]]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\",\"topic\",\"category\"],false,[\"each\",\"-track-array\",\"unless\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/above-user-summary-stats/custom-user-summary.js",
    "scope": () => [_i18n.default, _topicFormatter.default, injectUser, _categoryLink.default, _truthHelpers.eq, _userSummaryCategorySearch.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "custom-user-summary:CustomUserSummary"));
  var _default = _exports.default = CustomUserSummary;
});