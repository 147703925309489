define("discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/menu-item-end/timeago", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.outletArgs.notification}}
    {{format-date
      this.outletArgs.notification.created_at
      format="medium-with-ago"
    }}
  {{/if}}
  */
  {
    "id": "oT5UBfF/",
    "block": "[[[41,[30,0,[\"outletArgs\",\"notification\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"outletArgs\",\"notification\",\"created_at\"]]],[[\"format\"],[\"medium-with-ago\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"format-date\"]]",
    "moduleName": "discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/menu-item-end/timeago.hbs",
    "isStrictMode": false
  });
});