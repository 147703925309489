define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/upcoming-meetup-route", ["exports", "@ember/service", "discourse/lib/api"], function (_exports, _service, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.modifyClass("route:discovery.category", {
      pluginId: "local-guides-connect-navigation",
      router: (0, _service.service)(),
      siteSettings: (0, _service.service)(),
      redirect(model, transition) {
        if (model.category.id !== parseInt(this.siteSettings.meetup_category, 10)) {
          this._super(transition);
          return;
        }
        this.router.transitionTo("discovery.latestCategory", transition.to.params.category_slug_path_with_id, {
          queryParams: {
            ...transition.to.queryParams,
            q: "in:upcoming"
          }
        });
      }
    });
  });
});