define("discourse/plugins/discourse-local-guides-connect/discourse/lib/is-meetup-submission", ["exports", "discourse/models/composer", "discourse/plugins/discourse-local-guides-connect/discourse/lib/meetup-helper"], function (_exports, _composer, _meetupHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isMeetupSubmission;
  function isMeetupSubmission(model) {
    if ((0, _meetupHelper.isRecapTopic)(model)) {
      return false;
    }
    if (model.get("category.topic_url") && model.get("category.topic_url") === model.get("post.topic.url")) {
      return false;
    }
    if (model.get("metaData.isMeetup")) {
      return true;
    }
    const isEditingOp = model.get("post.firstPost") && model.get("action") === _composer.default.EDIT;

    // Edit OP of an existing meetup submission
    if (isEditingOp && model.get("post.topic") && model.get("post.topic.archetype") === "private_message" && model.get("post.topic.allowedGroups").find(group => group.name === model.siteSettings.meetup_submission_group)) {
      return true;
    }
    if (!model.get("targetRecipients")) {
      const isMeetupCategory = model.get("category.id") === parseInt(model.siteSettings.meetup_category, 10);
      const isPrivateCategory = model.get("category.read_restricted");

      // New topic or editing OP in meetup category
      return (model.get("action") === _composer.default.CREATE_TOPIC || isEditingOp) && (isMeetupCategory || isPrivateCategory && model.get("post.event"));
    }
    const recipients = model.get("targetRecipients").split(",");
    return recipients.includes(model.siteSettings.meetup_submission_group);
  }
});