define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/create-account-after-modal-footer/cancel-button", ["exports", "@ember/modifier", "discourse/helpers/route-action", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _modifier, _routeAction, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <button
      type="button"
      class="login-cancel"
      {{on "click" (routeAction "closeModal")}}
    >
      <span>{{i18n "js.cancel_value"}}</span>
    </button>
  
  */
  {
    "id": "o8FXTU6F",
    "block": "[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"login-cancel\"],[24,4,\"button\"],[4,[32,0],[\"click\",[28,[32,1],[\"closeModal\"],null]],null],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[32,2],[\"js.cancel_value\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/create-account-after-modal-footer/cancel-button.js",
    "scope": () => [_modifier.on, _routeAction.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "cancel-button"));
});