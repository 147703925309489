define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/remove-expand-profile", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function removeProfileExpand(api) {
    api.modifyClass("controller:user", {
      pluginId: "remove-profile-expand",
      canExpandProfile: false,
      forceExpand: true
    });
  }
  var _default = _exports.default = {
    name: "remove-profile-expand",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", removeProfileExpand);
    }
  };
});