define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/post-flag-confirmation", ["exports", "discourse/lib/api", "discourse/plugins/discourse-local-guides-connect/discourse/components/modal/flag-confirmation-dialog"], function (_exports, _api, _flagConfirmationDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.0", api => {
    api.onAppEvent("post:flag-created", () => {
      const modal = api.container.lookup("service:modal");
      modal.show(_flagConfirmationDialog.default);
    });
  });
});