define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-going-button/yes-button", ["exports", "discourse/components/d-button", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const YesButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      @label="event.going_yes"
      @action={{@outletArgs.markAsGoing}}
      class="going-button"
    />
  
  */
  {
    "id": "jRLR8nvq",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"going-button\"]],[[\"@label\",\"@action\"],[\"event.going_yes\",[30,1,[\"markAsGoing\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/discourse-post-event-status-going-button/yes-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "yes-button:YesButton"));
  var _default = _exports.default = YesButton;
});