define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/trim-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = trimString;
  function trimString(string) {
    return string ? string.trim() : null;
  }
});