define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/includes-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = includesUrl;
  function includesUrl(fullString, partString) {
    return fullString.includes(partString);
  }
});