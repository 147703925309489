define("discourse/plugins/discourse-local-guides-connect/discourse/components/chips", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "truth-helpers", "discourse/components/popup-input-tip", "discourse/helpers/concat-class", "discourse-i18n", "discourse/plugins/discourse-local-guides-connect/discourse/helpers/category-name", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _truthHelpers, _popupInputTip, _concatClass, _discourseI18n, _categoryName, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Chips extends _component.default {
    isMultiselect = (() => this.args.max !== 1)();
    isSelected(id) {
      return this.isMultiselect ? this.args.selected?.includes(id) : this.args.selected === id;
    }
    static #_ = (() => dt7948.n(this.prototype, "isSelected", [_object.action]))();
    tagLocalizedName(tag) {
      return _discourseI18n.default.t(`tag_locale_${tag.name.toLowerCase()}`, {
        defaultValue: tag.description || tag.name
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "tagLocalizedName", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="g-chips"
          role="listbox"
          aria-multiselectable={{if this.isMultiselect "true" "false"}}
          ...attributes
        >
          {{#each @items as |item|}}
            {{#if (gt item.name.length 2)}}
              <button
                type="button"
                role="option"
                class={{concatClass
                  "g-chips__item"
                  (if (this.isSelected item.id) "g-chips__item--selected")
                }}
                data-content={{item.name}}
                data-description={{item.description}}
                aria-selected={{if (this.isSelected item.id) "true" "false"}}
                {{on "click" (fn @onSelect item.id)}}
              >
                <span class="g-chips__label">
                  {{#if @isTag}}
                    {{this.tagLocalizedName item}}
                  {{else}}
                    {{categoryName item}}
                  {{/if}}
                </span>
              </button>
            {{/if}}
          {{/each}}
        </div>
    
        <PopupInputTip @validation={{@validation}} />
      
    */
    {
      "id": "4xtGganw",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"g-chips\"],[24,\"role\",\"listbox\"],[16,\"aria-multiselectable\",[52,[30,0,[\"isMultiselect\"]],\"true\",\"false\"]],[17,1],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,2]],null]],null],null,[[[41,[28,[32,0],[[30,3,[\"name\",\"length\"]],2],null],[[[1,\"          \"],[11,\"button\"],[24,\"role\",\"option\"],[16,0,[28,[32,1],[\"g-chips__item\",[52,[28,[30,0,[\"isSelected\"]],[[30,3,[\"id\"]]],null],\"g-chips__item--selected\"]],null]],[16,\"data-content\",[30,3,[\"name\"]]],[16,\"data-description\",[30,3,[\"description\"]]],[16,\"aria-selected\",[52,[28,[30,0,[\"isSelected\"]],[[30,3,[\"id\"]]],null],\"true\",\"false\"]],[24,4,\"button\"],[4,[32,2],[\"click\",[28,[32,3],[[30,4],[30,3,[\"id\"]]],null]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"g-chips__label\"],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"                \"],[1,[28,[30,0,[\"tagLocalizedName\"]],[[30,3]],null]],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[28,[32,4],[[30,3]],null]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[3]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[32,5],null,[[\"@validation\"],[[30,6]]],null],[1,\"\\n  \"]],[\"&attrs\",\"@items\",\"item\",\"@onSelect\",\"@isTag\",\"@validation\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/chips.js",
      "scope": () => [_truthHelpers.gt, _concatClass.default, _modifier.on, _helper.fn, _categoryName.default, _popupInputTip.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Chips;
});