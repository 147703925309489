define("discourse/plugins/discourse-local-guides-connect/discourse/components/top-filters", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/helpers/concat-class", "discourse/lib/filter-mode", "discourse/models/nav-item", "discourse/services/scroll-direction", "select-kit/components/category-chooser", "select-kit/components/combo-box", "discourse/plugins/discourse-local-guides-connect/discourse/helpers/category-country", "discourse/plugins/discourse-local-guides-connect/discourse/lib/country-options", "discourse/plugins/discourse-local-guides-connect/discourse/components/country-selector", "discourse/plugins/discourse-local-guides-connect/discourse/components/tags-intersection-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _concatClass, _filterMode, _navItem, _scrollDirection3, _categoryChooser, _comboBox, _categoryCountry, _countryOptions, _countrySelector, _tagsIntersectionChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TAGS_REGEX = /tags:(\S+)/;
  class TopFilters extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "topicTrackingState", [_service.service]))();
    #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "scrollDirection", [_service.service]))();
    #scrollDirection = (() => (dt7948.i(this, "scrollDirection"), void 0))();
    get scrollingDown() {
      return this.scrollDirection.lastScrollDirection === _scrollDirection3.SCROLLED_DOWN;
    }
    get category() {
      return this.router.currentRoute.attributes.category;
    }
    get selectedNavItem() {
      return this.navItems?.find(item => item.active)?.href;
    }
    get countryCode() {
      return this.router.currentRoute.queryParams.q?.match(TAGS_REGEX)?.[1]?.split("+")?.find(tag => tag.length === 2) ?? null;
    }
    get additionalTags() {
      return this.router.currentRoute.queryParams.q?.match(TAGS_REGEX)?.[1]?.split("+")?.filter(tag => tag.length > 2) ?? [];
    }
    get countryTags() {
      return _countryOptions.COUNTRIES;
    }
    get shouldRenderCategory() {
      return !this.category;
    }
    get shouldRenderCountry() {
      return !this.category || (0, _categoryCountry.hasCategoryCountry)(this.category.id, this.siteSettings);
    }
    get navItems() {
      const items = _navItem.default.buildList(this.category, {
        currentRouteQueryParams: this.router.currentRoute.queryParams,
        tagId: this.topicTrackingState.filterTag,
        siteSettings: this.siteSettings
      });
      items.forEach(item => {
        if (item.active !== undefined) {
          return;
        }
        const filterType = (0, _filterMode.filterTypeForMode)(this.discovery.currentTopicList.filter);
        item.active = item.filterType === filterType;
      });
      return items;
    }
    selectNavItem(navItem) {
      this.router.transitionTo(navItem);
    }
    static #_6 = (() => dt7948.n(this.prototype, "selectNavItem", [_object.action]))();
    setCountryCode(countryCode) {
      this.setTags([countryCode, ...this.additionalTags]);
    }
    static #_7 = (() => dt7948.n(this.prototype, "setCountryCode", [_object.action]))();
    onChangeCategory(category) {
      const meetupCategoryId = parseInt(this.siteSettings.meetup_category, 10);
      let query = this.router.currentRoute.queryParams.q;
      if (this.category?.id === meetupCategoryId) {
        query = query.replace(/in:(upcoming|past|attending|recaps|messages)/, "");
      }
      if (category?.id === meetupCategoryId) {
        // add in:upcoming
        query = query ? `${query} in:upcoming` : "in:upcoming";
      }
      const route = _navItem.default.pathFor("latest", {
        category
      });
      this.navigateTo(route, query);
    }
    static #_8 = (() => dt7948.n(this.prototype, "onChangeCategory", [_object.action]))();
    onChangeTags(tags) {
      this.setTags([this.countryCode, ...tags]);
    }
    static #_9 = (() => dt7948.n(this.prototype, "onChangeTags", [_object.action]))();
    setTags(allTags) {
      const q = this.router.currentRoute.queryParams.q;
      const tags = allTags.filter(Boolean);
      let query = tags.length > 0 ? `tags:${tags.join("%2B")}` : "";
      if (TAGS_REGEX.test(q)) {
        query = q.replace(TAGS_REGEX, query);
      } else {
        query = q ? `${q} ${query}` : query;
      }
      const route = _navItem.default.pathFor("latest", {
        category: this.category
      });
      this.navigateTo(route, query);
    }
    navigateTo(route, query) {
      const queryString = query ? `?q=${query.trim()}` : "";
      this.router.transitionTo(`${route}${queryString}`);
    }
    static #_10 = (() => dt7948.n(this.prototype, "navigateTo", [_object.action]))();
    static #_11 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class={{concatClass
            "g-top-filters"
            (if this.scrollingDown "g-top-filters--hidden")
          }}
        >
          <ComboBox
            class="g-top-filters__type"
            @value={{this.selectedNavItem}}
            @content={{this.navItems}}
            @onChange={{this.selectNavItem}}
            @labelProperty="displayName"
            @valueProperty="href"
          />
    
          {{#if this.shouldRenderCategory}}
            <CategoryChooser
              @value={{this.category.id}}
              @onChangeCategory={{this.onChangeCategory}}
              @allowRestrictedCategories={{true}}
              @options={{hash
                autoInsertNoneItem=true
                none="filters.categories.any"
              }}
            />
          {{/if}}
    
          <TagsIntersectionChooser
            @currentCategory={{this.category}}
            @mainTag={{null}}
            @additionalTags={{this.additionalTags}}
            @options={{hash
              categoryId=this.category.id
              allowAny=false
              everyTag=true
              none="filters.tags.any"
              hiddenValues=this.countryTags
            }}
            @onChange={{this.onChangeTags}}
          />
    
          {{#if this.shouldRenderCountry}}
            <CountrySelector
              @value={{this.countryCode}}
              @onChange={{this.setCountryCode}}
              @noneLabel="filters.countries.any"
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "y1fF8Btk",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"g-top-filters\",[52,[30,0,[\"scrollingDown\"]],\"g-top-filters--hidden\"]],null]],[12],[1,\"\\n      \"],[8,[32,1],[[24,0,\"g-top-filters__type\"]],[[\"@value\",\"@content\",\"@onChange\",\"@labelProperty\",\"@valueProperty\"],[[30,0,[\"selectedNavItem\"]],[30,0,[\"navItems\"]],[30,0,[\"selectNavItem\"]],\"displayName\",\"href\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldRenderCategory\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@value\",\"@onChangeCategory\",\"@allowRestrictedCategories\",\"@options\"],[[30,0,[\"category\",\"id\"]],[30,0,[\"onChangeCategory\"]],true,[28,[32,3],null,[[\"autoInsertNoneItem\",\"none\"],[true,\"filters.categories.any\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[32,4],null,[[\"@currentCategory\",\"@mainTag\",\"@additionalTags\",\"@options\",\"@onChange\"],[[30,0,[\"category\"]],null,[30,0,[\"additionalTags\"]],[28,[32,3],null,[[\"categoryId\",\"allowAny\",\"everyTag\",\"none\",\"hiddenValues\"],[[30,0,[\"category\",\"id\"]],false,true,\"filters.tags.any\",[30,0,[\"countryTags\"]]]]],[30,0,[\"onChangeTags\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldRenderCountry\"]],[[[1,\"        \"],[8,[32,5],null,[[\"@value\",\"@onChange\",\"@noneLabel\"],[[30,0,[\"countryCode\"]],[30,0,[\"setCountryCode\"]],\"filters.countries.any\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/top-filters.js",
      "scope": () => [_concatClass.default, _comboBox.default, _categoryChooser.default, _helper.hash, _tagsIntersectionChooser.default, _countrySelector.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopFilters;
});