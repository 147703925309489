define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/google-maps-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function addScript(src) {
    let s = document.createElement("script");
    s.setAttribute("src", src);
    s.setAttribute("async", "true");
    document.head.appendChild(s);
  }
  var _default = _exports.default = {
    name: "load-google-maps-script",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const lang = "en";
      const key = siteSettings.google_maps_api_key;
      if (!window.googleMapsCallback) {
        window.googleMapsCallback = () => {};
      }
      addScript(`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=${lang}&callback=googleMapsCallback`);
    }
  };
});