define("discourse/plugins/discourse-local-guides-connect/discourse/components/modal/tooltip-modal", ["exports", "discourse/components/d-modal", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dModal, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TooltipModal = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DModal @closeModal={{@closeModal}}>
      <:body>
        {{@model.content}}
      </:body>
    </DModal>
  
  */
  {
    "id": "p5qyVRZg",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@closeModal\"],[[30,1]]],[[\"body\"],[[[[1,\"\\n      \"],[1,[30,2,[\"content\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\",\"@model\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/modal/tooltip-modal.js",
    "scope": () => [_dModal.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "tooltip-modal:TooltipModal"));
  var _default = _exports.default = TooltipModal;
});