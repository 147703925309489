define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/override-topic-reply-count", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function overrideTopicReplyCount(api) {
    api.modifyClass("model:topic", dt7948.p({
      pluginId: "override-topic-reply-count",
      replyCount() {
        return this.real_reply_count;
      }
    }, [["method", "replyCount", [(0, _decorators.default)("posts_count")]]]));
  }
  var _default = _exports.default = {
    name: "override-topic-reply-count",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", overrideTopicReplyCount);
    }
  };
});