define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/clear-username", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.0", api => {
    api.addBeforeAuthCompleteCallback(options => {
      options.username = "";
      return true;
    });
  });
});