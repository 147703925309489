define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/ends-with-ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = endsWithEllipsis;
  function endsWithEllipsis(excerpt) {
    return excerpt.endsWith("&hellip;");
  }
});