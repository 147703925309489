define("discourse/plugins/discourse-local-guides-connect/discourse/components/reply-to-topic-btn", ["exports", "@glimmer/component", "@ember/controller", "@ember/modifier", "@ember/object", "@ember/service", "truth-helpers", "discourse/helpers/number", "discourse/models/composer", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _controller, _modifier, _object, _service, _truthHelpers, _number, _composer3, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReplyToTopicBtn extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "topicController", [(0, _controller.inject)("topic")]))();
    #topicController = (() => (dt7948.i(this, "topicController"), void 0))();
    async openReplyComposer(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.args.topic.real_reply_count === 0) {
        await this.router.transitionTo(this.args.topicUrl);
        const topic = this.topicController.get("model");
        this.composer.open({
          action: _composer3.REPLY,
          draftKey: topic.draft_key,
          draftSequence: topic.draft_sequence,
          topic
        });
      } else {
        await this.router.transitionTo(`${this.args.topicUrl}/2`);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "openReplyComposer", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <button
          type="button"
          class="g-topic__reply-button"
          {{on "click" this.openReplyComposer}}
        >
          {{#if (eq @topic.real_reply_count 0)}}
            <span>
              {{i18n "js.topic.reply.title"}}
            </span>
          {{else}}
            {{number @topic.real_reply_count}}
          {{/if}}
        </button>
      
    */
    {
      "id": "zu3ftHmm",
      "block": "[[[1,\"\\n    \"],[11,\"button\"],[24,0,\"g-topic__reply-button\"],[24,4,\"button\"],[4,[32,0],[\"click\",[30,0,[\"openReplyComposer\"]]],null],[12],[1,\"\\n\"],[41,[28,[32,1],[[30,1,[\"real_reply_count\"]],0],null],[[[1,\"        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"js.topic.reply.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[32,3],[[30,1,[\"real_reply_count\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/reply-to-topic-btn.js",
      "scope": () => [_modifier.on, _truthHelpers.eq, _i18n.default, _number.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ReplyToTopicBtn;
});