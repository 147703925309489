define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/has-meetup-ended", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasMeetupEnded;
  function hasMeetupEnded(a, b) {
    let end;
    if (b) {
      end = moment.utc(b);
    } else {
      end = moment.utc(a);
    }
    return end.isBefore(moment());
  }
});