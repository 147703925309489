define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/above-site-header/navigation", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/utilities", "discourse/models/composer", "discourse-common/lib/get-owner"], function (_exports, _component, _object, _service, _utilities, _composer3, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomNavigation extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "pmTopicTrackingState", [_service.service]))();
    #pmTopicTrackingState = (() => (dt7948.i(this, "pmTopicTrackingState"), void 0))();
    get homepage() {
      return `discovery.${(0, _utilities.defaultHomepage)()}`;
    }
    get submissionsCount() {
      return this.pmTopicTrackingState.lookupCount("new", {
        inboxFilter: "group",
        groupName: this.siteSettings.meetup_submission_group
      });
    }
    get active() {
      if (!this.args.route) {
        return;
      }
      if (this.router.currentRoute.name === "adminPlugins.show.settings") {
        return this.router.currentRoute.parent.params.plugin_id === this.args.routeParam;
      }
      if (this.args.routeParam && this.router.currentRoute && this.router.currentRoute.params.filter) {
        return this.router.currentRoute.params.filter === this.args.routeParam;
      }
      return this.router.isActive(this.args.route);
    }
    get currentCategory() {
      const currentTopic = (0, _getOwner.getOwnerWithFallback)(this).lookup("controller:topic")?.get("model");
      return currentTopic?.category ?? this.discovery.category;
    }
    get isPrivateCategory() {
      return this.currentCategory?.read_restricted;
    }
    async onMenuSelect(isMeetup) {
      if (isMeetup && !this.currentUser.staff) {
        if (this.isPrivateCategory) {
          return;
        }
        this.composer.openNewMessage({
          recipients: this.siteSettings.meetup_submission_group
        });
      } else {
        let category = this.currentCategory;

        // currentCategory is only used for "private flow"
        if (!category?.read_restricted) {
          category = isMeetup && this.currentUser.staff ? {
            id: parseInt(this.siteSettings.meetup_category, 10)
          } : undefined;
        }
        await this.composer.open({
          categoryId: category?.id,
          action: _composer3.CREATE_TOPIC,
          draftKey: _composer3.NEW_TOPIC_KEY,
          metaData: {
            isMeetup: isMeetup || undefined
          }
        });
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "onMenuSelect", [_object.action]))();
  }
  _exports.default = CustomNavigation;
});