define("discourse/plugins/discourse-local-guides-connect/discourse/components/country-selector", ["exports", "@ember/helper", "discourse/plugins/discourse-local-guides-connect/discourse/lib/country-options", "discourse/plugins/discourse-local-guides-connect/discourse/lib/custom-combo-box", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _countryOptions, _customComboBox, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <CustomComboBox
      @value={{@value}}
      @content={{countryOptions}}
      @nameProperty="label"
      @valueProperty="value"
      @onChange={{@onChange}}
      @options={{hash none=@noneLabel}}
      class="g-country-select"
    />
  
  */
  {
    "id": "0jDXFCOQ",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"g-country-select\"]],[[\"@value\",\"@content\",\"@nameProperty\",\"@valueProperty\",\"@onChange\",\"@options\"],[[30,1],[32,1],\"label\",\"value\",[30,2],[28,[32,2],null,[[\"none\"],[[30,3]]]]]],null],[1,\"\\n\"]],[\"@value\",\"@onChange\",\"@noneLabel\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/country-selector.js",
    "scope": () => [_customComboBox.default, _countryOptions.default, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "country-selector"));
});