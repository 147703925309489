define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/modify-account-delete-function", ["exports", "@ember/runloop", "discourse/lib/plugin-api", "discourse/lib/url", "discourse-common/lib/get-url", "I18n"], function (_exports, _runloop, _pluginApi, _url, _getUrl, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function modifyAccountDeleteFunction(api) {
    api.modifyClass("controller:preferences/account", {
      pluginId: "modify-account-delete-function",
      actions: {
        delete() {
          const translation_string = this.model.any_posts ? "user.anonymize_account_confirm" : "user.delete_account_confirm";
          this.dialog.alert({
            message: _I18n.default.t(translation_string),
            buttons: [{
              icon: "exclamation-triangle",
              label: _I18n.default.t("user.delete_account"),
              class: "btn-danger",
              action: () => {
                return this.model.delete().then(() => {
                  (0, _runloop.next)(() => {
                    this.dialog.alert({
                      message: _I18n.default.t("user.deleted_yourself"),
                      didConfirm: () => _url.default.redirectAbsolute((0, _getUrl.default)("/")),
                      didCancel: () => _url.default.redirectAbsolute((0, _getUrl.default)("/"))
                    });
                  });
                }, () => {
                  (0, _runloop.next)(() => this.dialog.alert(_I18n.default.t("user.delete_yourself_not_allowed")));
                  this.set("deleting", false);
                });
              }
            }, {
              label: _I18n.default.t("composer.cancel")
            }]
          });
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "modify-account-delete-function",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", modifyAccountDeleteFunction);
    }
  };
});