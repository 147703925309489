define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/composer-fields-below/disclaimer", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "I18n", "discourse/plugins/discourse-local-guides-connect/discourse/lib/is-meetup-submission", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _I18n, _isMeetupSubmission, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ComposerFieldsBelowDisclaimer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get shouldRender() {
      return (0, _isMeetupSubmission.default)(this.composerModel);
    }
    get composerModel() {
      return this.args.outletArgs.model;
    }
    get disclaimer() {
      return _I18n.default.t("meetup_form.disclaimer", {
        learn_more_link: `<a target="_blank" href='${this.siteSettings.lgc_meetup_disclaimer_link}'>
            ${_I18n.default.t("js.learn_more")}
           </a>`
      });
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <div class="g-composer__disclaimer">
            <span>
              {{htmlSafe this.disclaimer}}
            </span>
          </div>
        {{/if}}
      
    */
    {
      "id": "HHtnhrmA",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[10,0],[14,0,\"g-composer__disclaimer\"],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,0],[[30,0,[\"disclaimer\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/composer-fields-below/disclaimer.js",
      "scope": () => [_template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ComposerFieldsBelowDisclaimer;
});