define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/composer-open/composer-body-class", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/body-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _bodyClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ComposerBodyClass extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    get isFullscreenComposer() {
      const composerAction = this.composer.model?.action;
      return composerAction && composerAction !== "reply";
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.isFullscreenComposer}}
          {{bodyClass "g-fullscreen-composer"}}
        {{/if}}
      
    */
    {
      "id": "sbW+mJGi",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isFullscreenComposer\"]],[[[1,\"      \"],[1,[28,[32,0],[\"g-fullscreen-composer\"],null]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/composer-open/composer-body-class.js",
      "scope": () => [_bodyClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ComposerBodyClass;
});