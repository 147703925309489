define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/category-country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasCategoryCountry = hasCategoryCountry;
  _exports.isLocationMandatory = isLocationMandatory;
  function isLocationMandatory(categoryId, siteSettings) {
    const mandatoryCategories = siteSettings.country_field_mandatory_categories.split("|");
    const categoryIdStr = categoryId?.toString();
    return mandatoryCategories?.includes(categoryIdStr);
  }
  function hasCategoryCountry(categoryId, siteSettings) {
    const optionalCategories = siteSettings.country_field_optional_categories.split("|");
    const categoryIdStr = categoryId?.toString();
    return optionalCategories?.includes(categoryIdStr) || isLocationMandatory(categoryId, siteSettings);
  }
});