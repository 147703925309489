define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/button-customization", ["exports", "@ember/object", "discourse/lib/api", "discourse/plugins/discourse-local-guides-connect/discourse/components/invite-post-menu"], function (_exports, _object, _api, _invitePostMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.0", api => {
    api.removePostMenuButton("reply", attrs => attrs.user.username === "system");
    api.removePostMenuButton("like", attrs => attrs.user.username === "system");
    api.addPostMenuButton("post-invite", attrs => {
      const eventDetails = attrs.topic?.postStream?.posts[0]?.event;
      const eventDate = eventDetails?.ends_at || eventDetails?.starts_at;
      if (attrs.post_number > 1 || !eventDate || moment(eventDate).isBefore(moment())) {
        return false;
      }
      return {
        action: "openInviteMenu",
        icon: "user-plus",
        className: "g-invite-button-post",
        title: "js.topic.share.invite_users",
        position: "second",
        sendActionEvent: true
      };
    });
    api.modifyClass("component:composer-editor", Superclass => class extends Superclass {
      extraButtons(toolbar) {
        if (this.composer.allowUpload && this.composer.uploadIcon) {
          toolbar.addButton({
            id: "upload",
            group: "insertions",
            icon: this.composer.uploadIcon,
            title: "upload",
            sendAction: this.showUploadModal
          });
        }
        toolbar.addButton({
          id: "options",
          group: "extras",
          icon: "cog",
          title: "composer.options",
          sendAction: this.onExpandPopupMenuOptions.bind(this),
          popupMenu: true
        });
      }
      static #_ = (() => dt7948.n(this.prototype, "extraButtons", [_object.action]))();
    });
    api.modifyClass("widget:post-menu", Superclass => class extends Superclass {
      openInviteMenu(event) {
        this.menu.show(event.target, {
          identifier: "invite-post-menu",
          component: _invitePostMenu.default,
          modalForMobile: false,
          autofocus: true,
          data: {
            post: this.findAncestorModel()
          }
        });
      }
      static #_ = (() => dt7948.n(this.prototype, "openInviteMenu", [_object.action]))();
    });
  });
});