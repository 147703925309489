define("discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/user-icon", ["exports", "discourse/lib/api", "discourse/plugins/discourse-local-guides-connect/discourse/components/user-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _api, _userIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.0", api => {
    api.headerIcons.add("g-user", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="g-user-icon">
          <UserIcon />
        </li>
      
    */
    {
      "id": "fgS3FHKc",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"g-user-icon\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/api-initializers/user-icon.js",
      "scope": () => [_userIcon.default],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  });
});