define("discourse/plugins/discourse-local-guides-connect/discourse/lib/meetup-regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NEW_LINES_REGEX = _exports.IMAGE_REGEX = _exports.EVENT_REGEX = _exports.EVENT_PARAMS_REGEX = void 0;
  const NEW_LINES_REGEX = _exports.NEW_LINES_REGEX = /^\s*\n+/;
  const EVENT_REGEX = _exports.EVENT_REGEX = /\[event[^\]]*\][\s\S]*?\[\/event\]/;
  const EVENT_PARAMS_REGEX = _exports.EVENT_PARAMS_REGEX = /(\w+)\s*=\s*"([^"]*)"/g;
  const IMAGE_REGEX = _exports.IMAGE_REGEX = /!\[[^\]]*\]\(([^)]+)\)/;
});