define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/user-setup", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-local-guides-connect/discourse/components/modal/local-guides-setup-modal", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants"], function (_exports, _pluginApi, _localGuidesSetupModal, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "local-guides-user-setup",
    after: "load-google-maps-script",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        window.googleMapsCallback = () => {
          this.setupLocalGuides(api);
        };
      });
    },
    setupLocalGuides(api) {
      if (!api.getCurrentUser()) {
        return;
      }
      if (api.getCurrentUser().custom_fields?.[_constants.USER_SETUP_AT_FIELD]) {
        return;
      }
      const modal = api.container.lookup("service:modal");
      modal.show(_localGuidesSetupModal.default);
    }
  };
});