define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/notifications-tab-titles", ["exports", "discourse/lib/plugin-api", "I18n"], function (_exports, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function changeNotificationTabs(api) {
    // this is not working, tried lib:user-menu/tab too
    api.modifyClass("component:user-menu/menu-tab", {
      pluginId: "change-notification-tabs",
      get title() {
        let id = this.args.tab.id.replace(/-/g, "_");
        id = id.replace(/_with_unread$/, "");
        let key = `user_menu.tabs.${id}`;
        return _I18n.default.t(key);
      }
    });
  }
  var _default = _exports.default = {
    name: "change-notification-tabs",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", changeNotificationTabs);
    }
  };
});