define("discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/user-post-names/name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless (trimString @outletArgs.model.name)}}
    {{@outletArgs.model.username}}
  {{/unless}}
  */
  {
    "id": "IENo1Gib",
    "block": "[[[41,[51,[28,[37,1],[[30,1,[\"model\",\"name\"]]],null]],[[[1,\"  \"],[1,[30,1,[\"model\",\"username\"]]],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"unless\",\"trimString\"]]",
    "moduleName": "discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/user-post-names/name.hbs",
    "isStrictMode": false
  });
});