define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/before-topic-list/top-filters-outlet", ["exports", "discourse/plugins/discourse-local-guides-connect/discourse/components/top-filters", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _topFilters, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TopFiltersOutlet = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    <TopFilters />
  */
  {
    "id": "OAVjetBT",
    "block": "[[[8,[32,0],null,null,null]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/before-topic-list/top-filters-outlet.js",
    "scope": () => [_topFilters.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "top-filters-outlet:TopFiltersOutlet"));
  var _default = _exports.default = TopFiltersOutlet;
});