define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/post-date-no-modal", ["exports", "discourse/helpers/node", "discourse/lib/api"], function (_exports, _node, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.reopenWidget("post-date", {
      tagName: "div.post-info.post-date",
      html(attrs) {
        let date,
          linkClassName = "post-date";
        if (attrs.wiki && attrs.lastWikiEdit) {
          linkClassName += " last-wiki-edit";
          date = new Date(attrs.lastWikiEdit);
        } else {
          date = new Date(attrs.created_at);
        }
        return this.attach("link", {
          rawLabel: (0, _node.dateNode)(date),
          className: linkClassName,
          omitSpan: true,
          title: "post.sr_date",
          href: attrs.shareUrl
        });
      }
    });
  });
});