define("discourse/plugins/discourse-local-guides-connect/discourse/helpers/ms", ["exports", "@ember/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ms;
  function ms(icon, args) {
    const {
      fill = null,
      weight = null,
      grade = null,
      size = null
    } = args || {};
    let styles = [];
    if (fill !== null) {
      styles.push(`'FILL' ${fill}`);
    }
    if (weight !== null) {
      styles.push(`'wght' ${weight}`);
    }
    if (grade !== null) {
      styles.push(`'GRAD' ${grade}`);
    }
    if (size !== null) {
      styles.push(`'opsz' ${size}`);
    }
    let styleAttribute = "";
    if (styles.length > 0) {
      styleAttribute = `style="font-variation-settings: ${styles.join(", ")};"`;
    }
    return (0, _template.htmlSafe)(`<i class="material-symbols-outlined" ${styleAttribute}>${icon}</i>`);
  }
});