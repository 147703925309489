define("discourse/plugins/discourse-local-guides-connect/discourse/lib/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USER_SETUP_AT_FIELD = _exports.ONBOARDED_AT_FIELD = _exports.LOCATION_FIELD = _exports.IS_GMAPS_LINK_PUBLIC_FIELD = _exports.FEATURED_TAG = _exports.EVENT_TYPE_FIELD = _exports.EVENT_TOPIC_ID_FIELD = _exports.EVENT_RECAP_ID_FIELD = void 0;
  const LOCATION_FIELD = _exports.LOCATION_FIELD = "local_guides_location";
  const EVENT_TYPE_FIELD = _exports.EVENT_TYPE_FIELD = "local_guides_event_type";
  const IS_GMAPS_LINK_PUBLIC_FIELD = _exports.IS_GMAPS_LINK_PUBLIC_FIELD = "local_guides_is_gmaps_link_public";
  const USER_SETUP_AT_FIELD = _exports.USER_SETUP_AT_FIELD = "local_guides_user_setup_at";
  const ONBOARDED_AT_FIELD = _exports.ONBOARDED_AT_FIELD = "local_guides_onboarded_at";
  const EVENT_RECAP_ID_FIELD = _exports.EVENT_RECAP_ID_FIELD = "local_guides_event_recap_id";
  const EVENT_TOPIC_ID_FIELD = _exports.EVENT_TOPIC_ID_FIELD = "local_guides_event_topic_id";
  const FEATURED_TAG = _exports.FEATURED_TAG = "featured";
});