define("discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/category-box-below-each-category/view-all", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    href="/c/{{this.outletArgs.category.slug}}/{{this.outletArgs.category.id}}"
    class="view-all"
  >
    <span>
      {{i18n "js.review.view_all"}}
      ({{this.outletArgs.category.topics_all_time}})
    </span>
  </a>
  */
  {
    "id": "QoKvGtjI",
    "block": "[[[10,3],[15,6,[29,[\"/c/\",[30,0,[\"outletArgs\",\"category\",\"slug\"]],\"/\",[30,0,[\"outletArgs\",\"category\",\"id\"]]]]],[14,0,\"view-all\"],[12],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"js.review.view_all\"],null]],[1,\"\\n    (\"],[1,[30,0,[\"outletArgs\",\"category\",\"topics_all_time\"]]],[1,\")\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-local-guides-connect/discourse/templates/connectors/category-box-below-each-category/view-all.hbs",
    "isStrictMode": false
  });
});